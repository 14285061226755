/* eslint-disable react/no-unescaped-entities */

import React, { useState } from 'react'

const FaqBuyExchange = () => {
  const [active, setActive] = useState(1)

  return (
    <section className='py-12 bg-white sm:py-16 lg:py-16'>
      <div className='px-4 mx-auto max-w-7xl sm:px-6 lg:px-8'>
        <div className='text-center'>
          <h2 className='text-3xl font-bold  sm:text-4xl xl:text-5xl font-pj'>
            Frequently Asked Questions
          </h2>
          <p className='max-w-lg mx-auto mt-6 text-base '>
            Everything you need to know about buying karma on SurveySwap
          </p>
        </div>

        <div className='max-w-4xl mx-auto mt-8 sm:mt-14'>
          <div className='space-y-4'>
            {[
              {
                id: 1,
                question:
                  "What's the difference between bought karma and earned karma?",
                answer: (
                  <p>
                    No difference at all! They work exactly the same way. Buying
                    karma is simply a time-saver that helps you climb the
                    rankings faster. Plus, your purchase helps us work on
                    SurveySwap full-time to make it even better for everyone.
                  </p>
                ),
              },
              {
                id: 2,
                question:
                  'How long do I have to wait before using karma I bought?',
                answer: (
                  <p>
                    Your karma is ready to use immediately! As long as you've
                    got a survey linked to the exchange and enough karma for at
                    least one respondent, your survey will appear in the list
                    right away. The extra karma you bought will also boost your
                    survey higher in the list.
                  </p>
                ),
              },
              {
                id: 3,
                question: "Can you guarantee I'll get all my responses?",
                answer: (
                  <p>
                    We're not interested in keeping your money if we can't
                    deliver what you need. While we can't guarantee users will
                    take your survey, as they are free to choose, we want to
                    ensure you get value for your purchase. If you don't get the
                    responses you paid for, we'll refund that karma or find
                    another way to help you get what you need!
                  </p>
                ),
              },
              {
                id: 4,
                question: 'Who will see my survey?',
                answer: (
                  <p>
                    Our community is primarily made up of students! About 80%
                    are bachelor or PhD students between 17-26 years old. We're
                    most active in four main countries: 🇳🇱 Netherlands, 🇬🇧
                    United Kingdom, 🇩🇪 Germany, and 🇺🇸 United States. If you're
                    looking for responses from other regions or age groups, the
                    SurveySwap exchange might not be your best fit - however, we
                    do have an{' '}
                    <a
                      href='https://surveyswap.io/agency'
                      className='text-blue-400 hover:text-blue-500 underline'
                    >
                      agency service
                    </a>{' '}
                    where we can access paid panels from all over the world.
                  </p>
                ),
              },
            ].map(item => (
              <div key={item.id} className='relative'>
                {item.id === 1 && (
                  <div className='absolute -inset-1'>
                    <div
                      className='w-full h-full mx-auto opacity-30 blur-lg filter'
                      style={{
                        background:
                          'linear-gradient(90deg, #44ff9a -0.55%, #44b0ff 22.86%, #8b44ff 48.36%, #ff6644 73.33%, #ebff70 99.34%)',
                      }}
                    ></div>
                  </div>
                )}

                <div className='relative overflow-hidden bg-white border border-gray-200 rounded-xl'>
                  <h3>
                    <button
                      onClick={() =>
                        setActive(active === item.id ? null : item.id)
                      }
                      className='flex items-center justify-between w-full px-6 py-5 text-xl font-bold text-left  sm:p-8 font-pj'
                    >
                      <span>{item.question}</span>
                      <span className='ml-4'>
                        {active === item.id ? (
                          <svg
                            className='w-6 h-6 '
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth='2'
                              d='M20 12H4'
                            />
                          </svg>
                        ) : (
                          <svg
                            className='w-6 h-6 '
                            xmlns='http://www.w3.org/2000/svg'
                            fill='none'
                            viewBox='0 0 24 24'
                            stroke='currentColor'
                          >
                            <path
                              strokeLinecap='round'
                              strokeLinejoin='round'
                              strokeWidth='2'
                              d='M12 4v16m8-8H4'
                            />
                          </svg>
                        )}
                      </span>
                    </button>
                  </h3>

                  {active === item.id && (
                    <div className='px-6 pb-6 sm:px-8 sm:pb-8'>
                      {item.answer}
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>

        <p className='text-center  text-base mt-9'>
          Still have questions? <span>Shoot us a line at </span>
          <a
            href='mailto:hello@surveyswap.io'
            className='font-medium text-blue-400 transition-all duration-200 hover:text-blue-500 focus:text-blue-500 hover:underline'
          >
            hello@surveyswap.io
          </a>
          <span> and we will sort you out!</span>
        </p>
      </div>
    </section>
  )
}

export default FaqBuyExchange
