/* eslint-disable */

import teamwork from '../../../assets/Onboarding/teamwork.svg'
import curiousCat from '../../../assets/Cats/curious-cat3.svg'
import hug from '../../../assets/Onboarding/hug.png'
import allKarmaMeme from '../../../assets/Onboarding/get-all-the-meme.jpg'
import survey from '../../../assets/Onboarding/survey.png'
import prizecup from '../../../assets/Onboarding/prize-cup.png'
import InformationTooltip from 'src/components/InformationToolTip/InformationToolTip'

const GiphyImage: React.FC<{ src: string; alt: string }> = ({ src, alt }) => (
  <div className='flex align-center justify-center text-center'>
    <img
      src={src}
      alt={alt}
      className='w-1/2' // matches your teamwork image size
    />
  </div>
)

const IntroductionPage = () => (
  <div className='max-w-[95%] mx-auto whitespace-normal'>
    <div className='bg-white p-5 rounded-lg'>
      <div className='mt-4 space-y-6'>
        {/* Welcome Section */}
        <div className='flex align-center justify-center text-center'>
          <img src={teamwork} alt='Cute cat' className='w-1/2 md:w-1/4' />
        </div>
        <div>
          <h4 className='font-semibold text-lg mb-2 text-center'>
            👋 Welcome to the SurveySwap Community!
          </h4>
          <InformationTooltip
            text='Completing this Introduction survey will earn your first 10 Karma points!'
            variant='success'
          />
        </div>

        <p>
          We&apos;re super excited to have you join our community! We created
          SurveySwap when we were students ourselves, facing the same challenge
          you might be dealing with now - finding research participants without
          breaking the bank. Today, we&apos;re a global community of students,
          marketers, and researchers all helping each other out.
        </p>
      </div>
    </div>
  </div>
)

const HowItWorks: React.FC = () => (
  <div className='max-w-[95%] mx-auto whitespace-normal'>
    <div className='bg-white p-5 rounded-lg'>
      <div className='mt-4 space-y-6'>
        {/* Title Section */}
        <div>
          <h4 className='font-semibold text-lg mb-4 text-center'>
            How SurveySwap Works
          </h4>
        </div>

        {/* Introduction */}
        <p className='mb-6'>
          On SurveySwap, you can get respondents by exchaning Karma. Think of it
          as our currency. There are several ways to earn Karma points.
        </p>
        <p className='mb-6'>🧐 Let's explore them together! </p>
      </div>
    </div>
  </div>
)

const BuyKarma = () => (
  <div className='max-w-[95%] mx-auto whitespace-normal'>
    <div className='bg-white p-5 rounded-lg'>
      <div className='mt-4 space-y-6'>
        <div className='flex align-center justify-center text-center'>
          <img src={prizecup} alt='Shopping cart' className='w-14 h-14' />
        </div>

        <div className='space-y-4'>
          <h4 className='font-semibold text-lg mb-2 text-center'>Buy Karma</h4>
          <div className='text-left'>
            <p className='mb-6'>
              Need responses quickly? The most common way to earn Karma is by
              taking surveys, but you can also purchase Karma to fast-track your
              research. Both methods work exactly the same way - it's just a
              time-saving option when you're in a rush, and helps keep
              SurveySwap running! 💫
            </p>
            <InformationTooltip
              text='Once you complete 10 surveys, get 25% off all purchases!'
              variant='success'
            />
          </div>
        </div>
      </div>
    </div>
  </div>
)

const TakeSurveys = () => (
  <div className='max-w-[95%] mx-auto whitespace-normal'>
    <div className='bg-white p-5 rounded-lg'>
      <div className='mt-4 space-y-6'>
        <div className='flex align-center justify-center text-center'>
          <img src={survey} alt='Survey' className='w-14 h-14' />
        </div>

        <div className='space-y-4'>
          <h4 className='font-semibold text-lg mb-2 text-center'>
            Take Surveys
          </h4>
          <div className='text-left'>
            <p className='mb-6'>
              On SurveySwap, you will find a big list of all the surveys people
              have posted. By taking surveys, you earn Karma, and when people
              take your survey - you give them Karma. Think of it as a "you help
              me, I help you" kind of deal!
            </p>
            <InformationTooltip
              text='Longer surveys mean more Karma points!'
              variant='success'
            />
          </div>
        </div>
      </div>
    </div>
  </div>
)

const ReferralKarma = () => (
  <div className='max-w-[95%] mx-auto whitespace-normal'>
    <div className='bg-white p-5 rounded-lg'>
      <div className='mt-4 space-y-6'>
        <div className='flex align-center justify-center text-center'>
          <img src={hug} alt='Friends' className='w-14 h-14' />
        </div>

        <div className='space-y-4'>
          <h4 className='font-semibold text-lg mb-2 text-center'>
            Bring Your Friends
          </h4>
          <div className='text-left'>
            <p className='mb-6'>
              Growing our community helps everyone get better research results.
              The more researchers we have, the more diverse responses you'll
              get!
            </p>
            <InformationTooltip
              text="For each friend who joins and participates, you'll earn 15 Karma points!"
              variant='success'
            />
          </div>
        </div>
      </div>
    </div>
  </div>
)

const WorkIsRewarded = () => (
  <div className='max-w-[95%] mx-auto whitespace-normal'>
    <div className='bg-white p-5 rounded-lg'>
      <div className='mt-4 space-y-6'>
        <div className='relative flex align-center justify-center text-center'>
          <img
            src={allKarmaMeme}
            alt='Karma visualization'
            className='w-1/2 md:w-1/3'
          />
          <div className='absolute -top-2 right-1/4 bg-black text-white px-3 py-1 rounded-xl text-sm'>
            get all the Karma!
          </div>
        </div>

        <div className='space-y-4'>
          <h4 className='font-semibold text-lg mb-2 text-center'>
            Work is Rewarded
          </h4>
          <div className='text-left'>
            <p className='mb-6'>
              More Karma = higher visibility. Your survey moves up in the
              rankings as you help others!
            </p>
            <InformationTooltip
              text='Active community members get their surveys seen first!'
              variant='success'
            />
          </div>
        </div>
      </div>
    </div>
  </div>
)

const GetToKnowYouBetter = () => (
  <div className='text-center flex flex-col items-center'>
    <h4 className='font-bold text-lg mb-4'>Let's get to know you better!</h4>
    <img
      src={curiousCat}
      alt='Curious cat'
      className='w-124 h-124 mb-4' // Made image bigger and added bottom margin
    />
  </div>
)
const GreatJobSoFar = () => (
  <div className='text-center'>
    <h3 className='font-bold mb-4 text-lg'>Thanks a million!</h3>
    <img
      src='https://cdn.pixabay.com/photo/2024/02/28/07/42/european-shorthair-8601492_640.jpg'
      alt='Cute cat'
      className='max-w-full h-auto rounded-lg'
    />
  </div>
)

const KarmaIntro = () => (
  <div className='max-w-[95%] mx-auto whitespace-normal'>
    <div className='bg-white p-5 rounded-lg'>
      <div className='mt-4 space-y-6'>
        <div className='text-center space-y-4'>
          <p className='font-medium'>
            On SurveySwap, you can get respondents by exchaning Karma. Think of
            it as our currency. There are several ways to earn Karma points
            Let's explore them together!
          </p>
        </div>
      </div>
    </div>
  </div>
)

const QualityControl = () => (
  <div className='max-w-[95%] mx-auto whitespace-normal'>
    <div className='bg-white p-5 rounded-lg'>
      <div className='mt-4 space-y-6'>
        <div>
          <h4 className='font-semibold text-lg mb-4 text-center'>
            🛡️ Ensuring Quality Responses
          </h4>
        </div>

        <div className='space-y-4'>
          {/* Community Monitoring */}
          <div className='flex items-start space-x-3'>
            <div className='text-2xl flex-shrink-0'>👀</div>
            <div>
              <p className='font-bold mb-2'>Community Monitoring</p>
              <p>
                Help us maintain high standards! You can report surveys that
                are:
              </p>
              <ul className='list-disc ml-5 mt-2 space-y-2'>
                <li>
                  Missing Karma codes - once we receive a second report, you
                  will get automatically get the Karma
                </li>
                <li>Offline or unavailable</li>
                <li>Containing low-quality questions</li>
              </ul>
            </div>
          </div>

          {/* Quality Assurance */}
          <div className='flex items-start space-x-3'>
            <div className='text-2xl flex-shrink-0'>⚖️</div>
            <div>
              <p className='font-bold mb-2'>Our Quality Promise</p>
              <p>
                We have a zero-tolerance policy for low-quality responses. If
                someone rushes through your survey, let us know and we'll refund
                your Karma and take action.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
)

const QualityFeedback = () => (
  <div className='max-w-[95%] mx-auto whitespace-normal'>
    <div className='bg-white p-5 rounded-lg'>
      <div className='mt-4 space-y-6'>
        <div>
          <h4 className='font-semibold text-lg mb-4 text-center'>
            💭 Your Thoughts Matter
          </h4>
        </div>

        <div className='space-y-4'>
          <p className='text-center'>
            We're always looking to improve! How do you feel about our quality
            control system?
          </p>

          <div className='text-left space-y-3'>
            <p className='font-semibold'>What do you think about:</p>
            <ul className='list-disc ml-5 space-y-2'>
              <li>Our Karma refund system for reported surveys?</li>
              <li>The community monitoring approach?</li>
              <li>Having a boost system for urgent surveys?</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>
)

const GrowTogether = () => (
  <div className='max-w-[95%] mx-auto whitespace-normal'>
    <div className='bg-white p-5 rounded-lg'>
      <div className='mt-4 space-y-6'>
        <div>
          <h4 className='font-semibold text-lg mb-4 text-center'>
            🌱 Let's Grow Together!
          </h4>
        </div>

        <div className='text-center space-y-4'>
          <p>
            Finding SurveySwap helpful? Spread the word and help our community
            grow!
          </p>
          <p>We'd love to hear your ideas on making SurveySwap even better.</p>
        </div>
      </div>
    </div>
  </div>
)
const SurveySwapLimitations = () => (
  <div className='max-w-[95%] mx-auto whitespace-normal'>
    <div className='bg-white p-5 rounded-lg'>
      <div className='mt-4 space-y-6'>
        <div className='space-y-4'>
          <div className='text-center'>
            <h4 className='font-semibold text-lg mb-2'>Important to Know</h4>
            <p>
              To help set the right expectations, here's what to expect on
              SurveySwap:
            </p>
          </div>

          <div className='space-y-3'>
            <div className='flex items-start space-x-3'>
              <div className='text-lg flex-shrink-0'>👥</div>
              <div>
                <p className='font-bold mb-1'>Student-Focused Platform</p>
                <p>
                  Most of our users (80%) are bachelor or PhD students aged
                  17-26. Perfect for academic research, but might not be
                  suitable if you need responses from other demographics.
                </p>
              </div>
            </div>

            <div className='flex items-start space-x-3'>
              <div className='text-lg flex-shrink-0'>🌍</div>
              <div>
                <p className='font-bold mb-1'>Available Countries</p>
                <p>
                  Our community is primarily based in the Netherlands, United
                  Kingdom, Germany, and United States. We might not be the best
                  fit if you need responses from other regions.
                </p>
              </div>
            </div>

            <div className='flex items-start space-x-3'>
              <div className='text-lg flex-shrink-0'>💰</div>
              <div>
                <p className='font-bold mb-1'>Not a place to earn money</p>
                <p>
                  SurveySwap is a research exchange platform, not a place to
                  earn money taking surveys.
                </p>
              </div>
            </div>

            <div className='flex items-start space-x-3'>
              <div className='text-lg flex-shrink-0'>🎯</div>
              <div>
                <p className='font-bold mb-1'>No Specific Targeting</p>
                <p>
                  Our user base consists mainly of students, we can't guarantee
                  responses from specific fields of study or other demographic
                  criteria.
                </p>
              </div>
            </div>

            <div className='flex items-start space-x-3'>
              <div className='text-lg flex-shrink-0'>📊</div>
              <div>
                <p className='font-bold mb-1'>No Response Guarantees</p>
                <p>
                  Users choose which surveys to take. While Karma helps increase
                  visibility, responses aren't guaranteed.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className='text-center space-y-2'>
          <p className='text-sm'>Is this a problem?</p>
          <a
            href='https://surveyswap.io/agency'
            target='_blank'
            rel='noopener noreferrer'
            className='inline-flex items-center text-blue-800 hover:text-black font-medium'
          >
            Find any audience with guaranteed responses our paid custom panel
            <svg
              className='w-4 h-4 ml-1'
              viewBox='0 0 20 20'
              fill='currentColor'
            >
              <path
                fillRule='evenodd'
                d='M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z'
                clipRule='evenodd'
              />
            </svg>
          </a>
        </div>
      </div>
    </div>
  </div>
)
// Rest of the components remain unchanged since they don't use the tooltip
export {
  IntroductionPage,
  HowItWorks,
  GetToKnowYouBetter,
  GreatJobSoFar,
  KarmaIntro,
  ReferralKarma,
  TakeSurveys,
  BuyKarma,
  QualityControl,
  QualityFeedback,
  GrowTogether,
  SurveySwapLimitations,
  WorkIsRewarded,
}
